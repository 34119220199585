<template>
    <div class="container-activation">
        <section class="wrapper-activacion">

            <div class="wrapper-icon">
                <img class="icon-dembora-password-recovery"
                    src="../assets/images/icon-dembora-color.svg"
                    alt="Logo product">
            </div>

            <div v-if="isPendingToActivate">
                <p class="forgot-password-title">{{ $t('recovery.forgot-password') }}</p>
                    <form @submit.prevent="onSubmit">

                        <div class="mdl-textfield mdl-js-textfield">
                            <label class="activation-label" for="forgot-email">{{ $t('recovery.forgot-email') }}</label>
                            <input class="mdl-textfield__input forgot-psw-input" v-model="email" type="email" id="forgot-email" autocomplete="email" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" required>
                        </div>

                        <div class="wrapper-submit">
                            <button type="submit" value="Activate" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
                                {{ $t('recovery.password-send-button') }}
                            </button>
                        </div>
                    </form>
            </div>

            <div v-else>
                <p class="confirmation-password">{{ $t('recovery.confirmation-password') }}</p>
            </div>
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </section>
    </div>
</template>

<script>
    import VueElementLoading from 'vue-element-loading'
    import {mapActions, mapState} from "vuex";

    export default {
        name: "ResetEmployeePassword",
        data() {
            return {
                isPendingToActivate: true,
                email: '',
            }
        },
        computed: {
            ...mapState({
                show: state => state.auth.requesting
            })
        },
        methods: {
            ...mapActions('auth', ['resetEmployee']),
            async onSubmit() {

                try {
                    await this.resetEmployee(this.email)
                    this.isPendingToActivate = false
                } catch(error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            }
        },
        components: { VueElementLoading }
    }
</script>
