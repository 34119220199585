import { render, staticRenderFns } from "./ResetEmployeePassword.vue?vue&type=template&id=51863287&"
import script from "./ResetEmployeePassword.vue?vue&type=script&lang=js&"
export * from "./ResetEmployeePassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports